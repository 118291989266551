import styled from 'styled-components';

export const ShelvesDesignerStyles = styled.div`
html, body {
	margin: 0;
	padding: 0;
	overflow: hidden;
	font-family: Arial;
}
@media (max-width: 1200px) {
  .vsplitter, .hsplitter {
    display: none;
  }
  .vsplitter,
  .splitter_panel,
  .right_panel,
  .left_panel,
  .top_panel,
  .bottom_panel,
  .horizontal_panel,
  .vertical_panel,
  .hsplitter {
    position: static !important;
    height: auto !important;
    width: auto !important;
  }
  .vsplitter:hover, .hsplitter:hover {
    opacity: 0.5;
  }
}

.btnSplitV, .btnSplitH, .btnDelete, .btnClone, .btnShelfOrientation {
	margin: 2px;
	width: 24px;
	height: 24px;	
	cursor: pointer;
}

img.delete-product-from-shelf { 
  width: 19px;
  height: 18px; 
  cursor: pointer;
  float: right;
}

div.product-shelf-entry {
  position: absolute;
}

div.product-shelf-entry:hover > div.floatCtrlsDiv {
  display: block;
}

.wood {
  background: url(img/wood.jpg) repeat-x;
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0px;
}

.remove {
  background: url(img/close.png) no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 2;
  right: 3px;
  top: 3px;
  cursor: pointer;
}

.shelfCell {
  overflow: hidden;
  height: 100%;
  min-width: 100px;
  min-height: 100px;
}
.picker {
  position: relative;
  float: left;
}
#shelfBkgImage {
  z-index: -1;
}
.swatch {
  width: 63px;
  height: 28px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover {
  position: fixed;
  left: 80px;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 100;
}
#shelfBackgroundImage, #newProductImage {
  margin-top: 5px;
  border: 1px dashed rgb(38 38 38);
  background: rgb(225 236 255);
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 6px;
  text-align: center;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
#newProductImage {
  margin-top: 20px;
}
#imageGallery img, #shelvesSettings img, #generatedJSON img {
	width: 30px;
	pointer-events: none;
}
#rootDiv {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
  background: white;
  overflow: auto;
  background-image: linear-gradient(to bottom, #c4c4c4, #afafaf, #9a9a9a, #868686, #727272);
}
#rootShelfCont {
	border: solid 2px #aaa;
	width: 1202px;
	height: 800px;
  position: relative;
}
#rootShelf {
  width: 100%;
  height: 100%;
  z-index: 1;
}
.toolSplitter {
	margin-top: -8px;
	margin-bottom: -8px;
	opacity: 0.7;
}
.toolSplitter img {
	cursor: default;
}
.tippy-box {
	font-family: Arial;
}
#settings, #shelves, #products {
  position: fixed;
  top: 0px;
  left: 41px;
  border: 1px solid rgba(170, 170, 170, 0.75);
  box-shadow: rgb(0 0 0 / 27%) 11px 0px 10px;
  padding: 8px 10px;
  display: none;
  background: rgb(255, 255, 255);
  z-index: 11;
  height: 100%;
  width: 320px;
}
#settings {
  overflow: auto;
}
#products, #shelves {
  width: 405px;
}
#productsPopUpClose, #shelvesPopUpClose {
  position: absolute;
    top: -4px;
    right: -4px;
    cursor: pointer;
    width: 40px !important;
    height: 40px !important;
}
#shelves img {
	height: 100px;
	padding: 10px;
}
#productsTitle, #shelvesTitle {
	font-weight: bold;
    background: rgb(201, 201, 201);
    padding: 5px;
    margin-top: 0px;
    border-bottom: solid 1px #818181;
    cursor: default;
}
#prodCont {
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  overflow: auto;
  top: 85px;
} 
#shelvesCont {
	left: 0px; 
	right: 0px;
	height: calc(100% - 36px);
	overflow: auto;
}
#productsResize, #shelvesResize {
	position: absolute;
  bottom: -1px;
  right: -1px;
  z-index: 2;
  width: 16px;
  height: 16px !important;
  padding: 0 !important;
}
#shelvesDesigner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  overflow: hidden;
  background-color: white;
}
#closeShelvesDesigner {
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
div.sdProd {
  float: left;
  padding: 5px;
  border: solid 1px #aaa;
  float: left;
  margin: 10px;
  cursor: default;
  width: 110px;
  cursor: pointer;
  position: relative;
}
div.sdProd:hover {
  background: #c5195361;
}
div.sdProd img {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  display: block;
  margin-top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

button.sdProdPrice, button.sdProdName, button.sdProdDim {
  font-size: 11px;
  padding: 2px;
  background: #ddd;
  width: 100%;
  cursor: pointer;
  border: solid 1px #aaa;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

div.prodPrice {
  padding: 4px;
  background: rgb(255, 255, 255);
  font-size: 11px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  padding-top: 0;
  padding-bottom: 0px;
  font-weight: bold;
  line-height: 14px;
  z-index: 4;
  height: 14px;
  max-height: 14px;
}
div.prodPlus {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: url(img/addright.png) center center / contain no-repeat;
  float: left;
  margin-left: 2px;
}
div.prodMinus {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: url(img/minus.png) center center / contain no-repeat;
  float: left;
  margin-left: 2px;
}
div.prodStackOnTop {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: url(img/addup.png) center center / contain no-repeat;
  float: left;
}

#toggleShelvesLib {
  border: solid 1px #888;
  padding: 3px;
  width: 100%;
  text-align: center;
  margin-top: 4px;
  border-radius: 4px;
}

#generatedJSON {
  
}
#jsonExport {
  position: fixed;
  bottom: -300px;
  width: 100%;
  height: 300px;
  border-top: solid 2px #aaa;
  background-color: rgba(255, 255, 255, 0.8);
  left: 0px; 
}


.splitter_panel {
  position: relative;
}
.splitter_panel .vsplitter {
    background-color: #aaa;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
    cursor: col-resize;
    z-index:900;
    width: 6px;
}

.splitter_panel .hsplitter {
    background-color: #aaa;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
    cursor: row-resize;
    z-index: 800;
    height: 6px;
}
.splitter_panel .vsplitter.splitter-invisible,
.splitter_panel .hsplitter.splitter-invisible {
    background: none;
}
.splitter_panel .vsplitter,
.splitter_panel .left_panel,
.splitter_panel .right_panel,
.splitter_panel .hsplitter,
.splitter_panel .top_panel,
.splitter_panel .bottom_panel,
.splitter_panel .horizontal_panel,
.splitter_panel .vertical_panel {
    position: absolute;
    overflow: hidden;
}

.splitter_panel .vsplitter,
.splitter_panel .left_panel,
.splitter_panel .right_panel,
.splitter_panel .vertical_panel {
  height: 100%;
}
.splitter_panel .hsplitter,
.splitter_panel .top_panel,
.splitter_panel .bottom_panel,
.splitter_panel .horizontal_panel {
  width: 100%;
}
.splitter_panel .top_panel,
.splitter_panel .left_panel,
.splitter_panel .vsplitter,
.splitter_panel .vertical_panel {
   top: 0;
}
.splitter_panel .top_panel,
.splitter_panel .bottom_panel,
.splitter_panel .left_panel,
.splitter_panel .hsplitter,
.splitter_panel .horizontal_panel {
   left: 0;
}
.splitter_panel .bottom_panel {
   bottom: 0;
}
.splitter_panel .right_panel {
   right: 0;
}
.splitterMask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}



@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

.navbar {
  position: fixed;
  top: 0rem;
  left: 0rem;
  background: rgb(255, 255, 255);
  bottom: 0rem;
  z-index: 1;
  padding-top: 6px;
  border-right: solid 1px #aaa;
}
.navbar__link {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  color: #6a778e;
  transition: 150ms ease all;
}
.navbar__link span {
  position: absolute;
  left: 100%;
  transform: translate(-3rem);
  margin-left: 1rem;
  opacity: 0;
  pointer-events: none;
  color: rgb(255 255 255);
  background: rgb(46 46 46);
  padding: 0.75rem;
  transition: all 250ms ease 0s;
  border-radius: 17.5px;
  white-space: nowrap;
}
.navbar__link:hover {
  color: #fff;
}
.navbar__link.active {
  background: rgb(197, 25, 83);
  color: #fff;
}
.navbar:not(:hover) .navbar__link:focus span, .navbar__link:hover span {
  opacity: 1;
  transform: translate(0);
}
.navbar__menu {
  position: relative;
}
.navbar__item:nth-child(9) {
  margin-top: 5rem;
  padding-top: 1.25rem;
  border-top: 1px solid #363664;
}
.navbar__item:last-child:before {
  content: "";
  position: absolute;
  opacity: 0;
  z-index: -1;
  top: 0px;
  left: 0rem;
  width: 2.5rem;
  height: 2.5rem;
  background: rgb(197, 25, 83);
  transition: all 150ms cubic-bezier(1, 0.2, 0.1, 1.2) 0s;
}
.navbar__item:first-child:nth-last-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(1) ~ li:hover ~ li:last-child:before {
  opacity: 1 !important;
}
.navbar__item:first-child:nth-last-child(1):last-child:hover:before, .navbar__item:first-child:nth-last-child(1) ~ li:last-child:hover:before {
  opacity: 1 !important;
}
.navbar__item:first-child:nth-last-child(1):last-child:hover:before, .navbar__item:first-child:nth-last-child(1) ~ li:last-child:hover:before {
  top: 0%;
  animation: gooeyEffect-1 250ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(2) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(2):last-child:hover:before, .navbar__item:first-child:nth-last-child(2) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(2):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(2) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(2):last-child:hover:before, .navbar__item:first-child:nth-last-child(2) ~ li:last-child:hover:before {
  top: 50%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(3) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(3):last-child:hover:before, .navbar__item:first-child:nth-last-child(3) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(3):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(3) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(3):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(3) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 33.3333333333%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(3):last-child:hover:before, .navbar__item:first-child:nth-last-child(3) ~ li:last-child:hover:before {
  top: 66.6666666667%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(4):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(4) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(4):last-child:hover:before, .navbar__item:first-child:nth-last-child(4) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(4):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(4) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(4):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(4) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 25%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(4):nth-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(4) ~ li:nth-child(3):hover ~ li:last-child:before {
  top: 50%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(4):last-child:hover:before, .navbar__item:first-child:nth-last-child(4) ~ li:last-child:hover:before {
  top: 75%;
  animation: gooeyEffect-4 150ms 1;
}
@keyframes gooeyEffect-4 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(5):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(5) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(5):last-child:hover:before, .navbar__item:first-child:nth-last-child(5) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(5):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(5) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(5):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(5) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 20%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(5):nth-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(5) ~ li:nth-child(3):hover ~ li:last-child:before {
  top: 40%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(5):nth-child(4):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(5) ~ li:nth-child(4):hover ~ li:last-child:before {
  top: 60%;
  animation: gooeyEffect-4 150ms 1;
}
@keyframes gooeyEffect-4 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(5):last-child:hover:before, .navbar__item:first-child:nth-last-child(5) ~ li:last-child:hover:before {
  top: 80%;
  animation: gooeyEffect-5 150ms 1;
}
@keyframes gooeyEffect-5 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(6):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(6) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(6):last-child:hover:before, .navbar__item:first-child:nth-last-child(6) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(6):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(6) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(6):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(6) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 16.6666666667%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(6):nth-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(6) ~ li:nth-child(3):hover ~ li:last-child:before {
  top: 33.3333333333%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(6):nth-child(4):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(6) ~ li:nth-child(4):hover ~ li:last-child:before {
  top: 50%;
  animation: gooeyEffect-4 150ms 1;
}
@keyframes gooeyEffect-4 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(6):nth-child(5):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(6) ~ li:nth-child(5):hover ~ li:last-child:before {
  top: 66.6666666667%;
  animation: gooeyEffect-5 150ms 1;
}
@keyframes gooeyEffect-5 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(6):last-child:hover:before, .navbar__item:first-child:nth-last-child(6) ~ li:last-child:hover:before {
  top: 83.3333333333%;
  animation: gooeyEffect-6 150ms 1;
}
@keyframes gooeyEffect-6 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(7):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(7) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(7):last-child:hover:before, .navbar__item:first-child:nth-last-child(7) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(7):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(7) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(7):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(7) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 14.2857142857%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(7):nth-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(7) ~ li:nth-child(3):hover ~ li:last-child:before {
  top: 28.5714285714%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(7):nth-child(4):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(7) ~ li:nth-child(4):hover ~ li:last-child:before {
  top: 42.8571428571%;
  animation: gooeyEffect-4 150ms 1;
}
@keyframes gooeyEffect-4 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(7):nth-child(5):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(7) ~ li:nth-child(5):hover ~ li:last-child:before {
  top: 57.1428571429%;
  animation: gooeyEffect-5 150ms 1;
}
@keyframes gooeyEffect-5 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(7):nth-child(6):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(7) ~ li:nth-child(6):hover ~ li:last-child:before {
  top: 71.4285714286%;
  animation: gooeyEffect-6 150ms 1;
}
@keyframes gooeyEffect-6 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(7):last-child:hover:before, .navbar__item:first-child:nth-last-child(7) ~ li:last-child:hover:before {
  top: 85.7142857143%;
  animation: gooeyEffect-7 150ms 1;
}
@keyframes gooeyEffect-7 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(8):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(8) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(8):last-child:hover:before, .navbar__item:first-child:nth-last-child(8) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(8):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(8) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(8):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(8) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 12.5%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(8):nth-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(8) ~ li:nth-child(3):hover ~ li:last-child:before {
  top: 25%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(8):nth-child(4):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(8) ~ li:nth-child(4):hover ~ li:last-child:before {
  top: 37.5%;
  animation: gooeyEffect-4 150ms 1;
}
@keyframes gooeyEffect-4 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(8):nth-child(5):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(8) ~ li:nth-child(5):hover ~ li:last-child:before {
  top: 50%;
  animation: gooeyEffect-5 150ms 1;
}
@keyframes gooeyEffect-5 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(8):nth-child(6):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(8) ~ li:nth-child(6):hover ~ li:last-child:before {
  top: 62.5%;
  animation: gooeyEffect-6 150ms 1;
}
@keyframes gooeyEffect-6 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(8):nth-child(7):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(8) ~ li:nth-child(7):hover ~ li:last-child:before {
  top: 75%;
  animation: gooeyEffect-7 150ms 1;
}
@keyframes gooeyEffect-7 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(8):last-child:hover:before, .navbar__item:first-child:nth-last-child(8) ~ li:last-child:hover:before {
  top: 87.5%;
  animation: gooeyEffect-8 150ms 1;
}
@keyframes gooeyEffect-8 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(9):last-child:hover:before, .navbar__item:first-child:nth-last-child(9) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(9):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 11.1111111111%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):nth-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:nth-child(3):hover ~ li:last-child:before {
  top: 22.2222222222%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):nth-child(4):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:nth-child(4):hover ~ li:last-child:before {
  top: 33.3333333333%;
  animation: gooeyEffect-4 150ms 1;
}
@keyframes gooeyEffect-4 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):nth-child(5):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:nth-child(5):hover ~ li:last-child:before {
  top: 44.4444444444%;
  animation: gooeyEffect-5 150ms 1;
}
@keyframes gooeyEffect-5 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):nth-child(6):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:nth-child(6):hover ~ li:last-child:before {
  top: 55.5555555556%;
  animation: gooeyEffect-6 150ms 1;
}
@keyframes gooeyEffect-6 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):nth-child(7):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:nth-child(7):hover ~ li:last-child:before {
  top: 66.6666666667%;
  animation: gooeyEffect-7 150ms 1;
}
@keyframes gooeyEffect-7 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):nth-child(8):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(9) ~ li:nth-child(8):hover ~ li:last-child:before {
  top: 77.7777777778%;
  animation: gooeyEffect-8 150ms 1;
}
@keyframes gooeyEffect-8 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(9):last-child:hover:before, .navbar__item:first-child:nth-last-child(9) ~ li:last-child:hover:before {
  top: 88.8888888889%;
  animation: gooeyEffect-9 150ms 1;
}
@keyframes gooeyEffect-9 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(10):last-child:hover:before, .navbar__item:first-child:nth-last-child(10) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(10):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 10%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):nth-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(3):hover ~ li:last-child:before {
  top: 20%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):nth-child(4):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(4):hover ~ li:last-child:before {
  top: 30%;
  animation: gooeyEffect-4 150ms 1;
}
@keyframes gooeyEffect-4 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):nth-child(5):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(5):hover ~ li:last-child:before {
  top: 40%;
  animation: gooeyEffect-5 150ms 1;
}
@keyframes gooeyEffect-5 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):nth-child(6):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(6):hover ~ li:last-child:before {
  top: 50%;
  animation: gooeyEffect-6 150ms 1;
}
@keyframes gooeyEffect-6 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):nth-child(7):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(7):hover ~ li:last-child:before {
  top: 60%;
  animation: gooeyEffect-7 150ms 1;
}
@keyframes gooeyEffect-7 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):nth-child(8):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(8):hover ~ li:last-child:before {
  top: 70%;
  animation: gooeyEffect-8 150ms 1;
}
@keyframes gooeyEffect-8 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):nth-child(9):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(10) ~ li:nth-child(9):hover ~ li:last-child:before {
  top: 80%;
  animation: gooeyEffect-9 150ms 1;
}
@keyframes gooeyEffect-9 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(10):last-child:hover:before, .navbar__item:first-child:nth-last-child(10) ~ li:last-child:hover:before {
  top: 90%;
  animation: gooeyEffect-10 150ms 1;
}
@keyframes gooeyEffect-10 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:hover ~ li:last-child:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(11):last-child:hover:before, .navbar__item:first-child:nth-last-child(11) ~ li:last-child:hover:before {
  opacity: 1;
}
.navbar__item:first-child:nth-last-child(11):nth-child(1):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(1):hover ~ li:last-child:before {
  top: 0%;
  animation: gooeyEffect-1 150ms 1;
}
@keyframes gooeyEffect-1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(2):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(2):hover ~ li:last-child:before {
  top: 9.0909090909%;
  animation: gooeyEffect-2 150ms 1;
}
@keyframes gooeyEffect-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(3):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(3):hover ~ li:last-child:before {
  top: 18.1818181818%;
  animation: gooeyEffect-3 150ms 1;
}
@keyframes gooeyEffect-3 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(4):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(4):hover ~ li:last-child:before {
  top: 27.2727272727%;
  animation: gooeyEffect-4 150ms 1;
}
@keyframes gooeyEffect-4 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(5):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(5):hover ~ li:last-child:before {
  top: 36.3636363636%;
  animation: gooeyEffect-5 150ms 1;
}
@keyframes gooeyEffect-5 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(6):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(6):hover ~ li:last-child:before {
  top: 45.4545454545%;
  animation: gooeyEffect-6 150ms 1;
}
@keyframes gooeyEffect-6 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(7):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(7):hover ~ li:last-child:before {
  top: 54.5454545455%;
  animation: gooeyEffect-7 150ms 1;
}
@keyframes gooeyEffect-7 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(8):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(8):hover ~ li:last-child:before {
  top: 63.6363636364%;
  animation: gooeyEffect-8 150ms 1;
}
@keyframes gooeyEffect-8 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(9):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(9):hover ~ li:last-child:before {
  top: 72.7272727273%;
  animation: gooeyEffect-9 150ms 1;
}
@keyframes gooeyEffect-9 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):nth-child(10):hover ~ li:last-child:before, .navbar__item:first-child:nth-last-child(11) ~ li:nth-child(10):hover ~ li:last-child:before {
  top: 81.8181818182%;
  animation: gooeyEffect-10 150ms 1;
}
@keyframes gooeyEffect-10 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.navbar__item:first-child:nth-last-child(11):last-child:hover:before, .navbar__item:first-child:nth-last-child(11) ~ li:last-child:hover:before {
  top: 90.9090909091%;
  animation: gooeyEffect-11 150ms 1;
}
@keyframes gooeyEffect-11 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}


.popbox.visible{
	opacity:1;
	z-index: 999;
    transition:opacity 0.3s ease;
}
.popbox.opened{
	display: block;
}

.popbox_locked .popbox_main_content.popbox_blur{
	filter:blur(15px);
}

html.popbox_locked {
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
}

.popbox{
	z-index: -999;
	position: fixed;
	width: 100%;
	max-width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  will-change: opacity;
  transition:opacity 0.3s ease;
  opacity:0;
  will-change: opacity;
	background: rgba(60, 52, 66, 0.7);
	padding:18px;
	padding-top:5%;
	overflow-y: auto;
	display: none;
}

.popbox_container{
	border-radius:5px;
	margin-bottom:18px;
	padding: 18px;
	margin-left:auto;
	margin-right:auto;
	background:#fff;
	max-width:400px;
}

.popbox .heading {
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
}

.popbox button {
  border: 1px solid rgb(84 84 84);
  border-radius: 3px;
  background: rgb(183 183 183);
  color: rgb(51, 51, 51);
  font-size: 14px;
  font-weight: bold;
  padding: 4px 10px;
  cursor: pointer;
  margin-right: 5px;
}

#shelfContainer {
  position: relative;
  left: 41px;
  top: 0px;
  width: calc(100% - 41px);
  height: 100%;
  overflow: hidden;
}

#shelfWrap {
  width: 100%;
  height: 100%;
  overflow: auto;
}

#shelfInnerWrap {
  padding: 20px;
  width: 3000px;
  height: 2500px;
}

.canvas-ruler-wrapper .canvas-ruler-box {
  z-index: 10;
}

.tab-panes .tab-pane{
  display: none;
}
.tab-panes .active{
  display: block;
  margin-top: -1px;
}
.tabs {
  margin-top: 4px;
  margin-left: 4px;
}
.tabs .tab {
  font-size: 14px;
  font-weight: bold;
  padding: 4px;
  padding-bottom: 1px;
  padding-top: 2px;
  margin-right: 4px;
  cursor: pointer;
}
.tabs .tab.active {
  border-bottom: solid 1px #aaa;
}

#allProductsCont {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 104px;
  overflow: auto;
  background: url(img/findprodbkg.png) no-repeat center center;
}

#btnSetRowsAndColumns {
  display: inline-block;
  width: 50px;
  height: 23px;
  border: 1px solid rgb(85, 85, 85);
  padding: 2px;
  background: rgb(238, 238, 238);
  line-height: 10px;
  margin-left: calc(50% - 25px);
  border-radius: 15px;
}
#prodSearchDiv {
  position: fixed;
  top: 50px;
}
#txtProdSearch {
  border: 1px solid rgb(170, 170, 170);
  border-radius: 70px;
  padding: 3px;
  width: 330px;
  margin-left: 10%;
  padding-left: 10px;
  padding-right: 10px;
}

#excelEditor {
  position: fixed;
  width: calc(100% - 41px);
  height: 100%;
  left: 41px;
  top: 0px;
  z-index: 10000;
  background: white;
  display: none;
}

.x-spreadsheet-toolbar {
  width: 100% !important;
}

.floatCtrlsDiv {
  background: #2e2e2ed4;
  position: absolute;
  z-index: 10;
  padding: 2px;
  width: 100%;
  display: none;
  zoom: 0.81;
  top: 0px;
}
`;
