import { BiCaretDown } from "react-icons/bi";
import AsyncSearch from "./asyncsearch";
import { useState } from 'react';
import { BlockUI, UnblockUI, UnblockUIError, APIURL, GetToken } from "./Helper";
import axios from "axios";
import MiniSignal from "mini-signals";
import $ from "jquery";

export const productsUpdated = new MiniSignal();

const SearchProducts = ({ onSearch, byNameOnly }) => {
  const [toggleAll, setToggleAll] = useState(false);
  const [categories, setCategories] = useState([]);

  const token = GetToken();

  const loadCategories = () => {
    if (categories.length === 0) {
      BlockUI();
      axios.get(APIURL + "categories.php?action=gac&t=" + token)
        .then((res) => {
          setCategories(res.data);
          UnblockUI();
          setToggleAll(!toggleAll);
        })
        .catch((error) => {
          console.error(error);
          UnblockUIError(error);
        });
    } else {
      setToggleAll(!toggleAll);
    }
  };

  const toggleAdvancedSearch = () => {
    loadCategories();
  };

  let lvl1 = "";
  let lvl2 = "";
  let lvl3 = "";
  let lvl4 = "";
  let lvl5 = "";
  let lvl6 = "";

  let pname = "";
  let pnnec = "";
  let rtlr = "";
  let cbrnd = "";
  let brnd = "";
  let sbrnd = "";
  let ctry = "";
  let dtnm = "";
  let unit = "";
  let szwt = "";
  let desc = "";
  let price = "";
  let stid = "";
  let nump = "";
  let dim = "";
  let ean = "";
  let upc = "";
  let asin = "";

  const findProducts = () => {   
    if ($("input[placeholder='Product Name']", "#mainSearch").val() !== "") {
      pname = $("input[placeholder='Product Name']", "#mainSearch").val();
    }
    if (document.getElementById("advancedSearchContainer") != null) {
      let extraParams = {
        action: "findprods"
      };
      if (lvl1 !== "") {
        extraParams.lvl1 = lvl1;
      }
      if (lvl2 !== "") {
        extraParams.lvl2 = lvl2;
      }
      if (lvl3 !== "") {
        extraParams.lvl3 = lvl3;
      }
      if (lvl4 !== "") {
        extraParams.lvl4 = lvl4;
      }
      if (lvl5 !== "") {
        extraParams.lvl5 = lvl5;
      }
      if (lvl6 !== "") {
        extraParams.lvl6 = lvl6;
      }
      if (pname !== "" && pname !== undefined) {
        extraParams.pname = pname;
      }
      if (pnnec !== "" && pnnec !== undefined) {
        extraParams.pnnec = pnnec;
      }
      if (rtlr !== "" && rtlr !== undefined) {
        extraParams.rtlr = rtlr;
      }
      if (cbrnd !== "" && cbrnd !== undefined) {
        extraParams.cbrnd = cbrnd;
      }
      if (brnd !== "" && brnd !== undefined) {
        extraParams.brnd = brnd;
      }
      if (sbrnd !== "" && sbrnd !== undefined) {
        extraParams.sbrnd = sbrnd;
      }
      if (ctry !== "" && ctry !== undefined) {
        extraParams.ctry = ctry;
      }
      if (dtnm !== "" && dtnm !== undefined) {
        extraParams.dtnm = dtnm;
      }
      if (unit !== "" && unit !== undefined) {
        extraParams.unit = unit;
      }
      if (szwt !== "" && szwt !== undefined) {
        extraParams.szwt = szwt;
      }
      if (desc !== "" && desc !== undefined) {
        extraParams.desc = desc;
      }
      if (price !== "" && price !== undefined) {
        extraParams.price = price;
      }
      if (stid !== "" && stid !== undefined) {
        extraParams.stid = stid;
      }
      if (nump !== ""&& nump !== undefined) {
        extraParams.nump = nump;
      }
      if (dim !== "" && dim !== undefined) {
        extraParams.dim = dim;
      }
      if (ean !== "" && ean !== undefined) {
        extraParams.ean = ean;
      }
      if (upc !== "" && upc !== undefined) {
        extraParams.upc = upc;
      }
      if (asin !== "" && asin !== undefined) {
        extraParams.asin = asin;
      }
      extraParams.t = token;
      if (Object.keys(extraParams).length > 1) {
        BlockUI();
        axios({
          method: "get",
          url: APIURL + "search.php",
          params: extraParams
        }).then((res) => {
          if (res.status === 200) {
            onSearch(res.data);
            UnblockUI();
          } else {
            UnblockUIError(res.statusText);
          }
        }).catch((error) => {
          UnblockUIError(error);
        });
      }
    } else {
      if (pname !== "" && pname !== undefined) {
        BlockUI();
        axios({
          method: "get",
          url: APIURL + "search.php",
          params: {
            action: "findprods",
            pname: pname,
            t: token
          }
        }).then((res) => {
          if (res.status === 200) {
            onSearch(res.data);
            UnblockUI();
          } else {
            UnblockUIError(res.statusText);
          }
        }).catch((error) => {
          UnblockUIError(error);
        });
      } else {
        // get all products
        BlockUI();
        axios.get(APIURL + "products.php?action=gap&t=" + token)
          .then((res) => {
            if (res.status === 200) {
              onSearch(res.data);
              UnblockUI();
            } else {
              UnblockUIError(res.statusText);
            }
          })
          .catch((error) => {
            console.error(error);
            UnblockUIError(error);
          });
      }
    }
  };

  const onPnameSet = val => {
    pname = val.selected[0];
  };
  const onPnnecSet = val => {
    pnnec = val.selected[0];
  };
  const onRtlrSet = val => {
    rtlr = val.selected[0];
  };
  const onCbrndSet = val => {
    cbrnd = val.selected[0];
  };
  const onBrndSet = val => {
    brnd = val.selected[0];
  };
  const onSbrndSet = val => {
    sbrnd = val.selected[0];
  };
  const onCtrySet = val => {
    ctry = val.selected[0];
  };
  const onDtnmSet = val => {
    dtnm = val.selected[0];
  };
  const onUnitSet = val => {
    unit = val.selected[0];
  };
  const onSzwtSet = val => {
    szwt = val.selected[0];
  };
  const onDescSet = val => {
    desc = val.selected[0];
  };
  const onPriceSet = val => {
    price = val.selected[0];
  };
  const onStidSet = val => {
    stid = val.selected[0];
  };
  const onNumpSet = val => {
    nump = val.selected[0];
  };
  const onDimSet = val => {
    dim = val.selected[0];
  };
  const onEanSet = val => {
    ean = val.selected[0];
  };
  const onUpcSet = val => {
    upc = val.selected[0];
  };
  const onAsinSet = val => {
    asin = val.selected[0];
  };

  const changeLvl1 = e => {
    const selLvl2 = document.getElementById('ctg_lvl2');
    if (e.target.value !== "_select_") {
      lvl1 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl2.value = "_select_";
      selLvl2.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl1 = "";
      selLvl2.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl2 = e => {
    const selLvl3 = document.getElementById('ctg_lvl3');
    if (e.target.value !== "_select_") {
      lvl2 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl3.value = "_select_";
      selLvl3.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl2 = "";
      selLvl3.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl3 = e => {
    const selLvl4 = document.getElementById('ctg_lvl4');
    if (e.target.value !== "_select_") {
      lvl3 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl4.value = "_select_";
      selLvl4.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl3 = "";
      selLvl4.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl4 = e => {
    const selLvl5 = document.getElementById('ctg_lvl5');
    if (e.target.value !== "_select_") {
      lvl4 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl5.value = "_select_";
      selLvl5.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl4 = "";
      selLvl5.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl5 = e => {
    const selLvl6 = document.getElementById('ctg_lvl6');
    if (e.target.value !== "_select_") {
      lvl5 = e.target.selectedOptions[0].text;//e.target.value;
      selLvl6.value = "_select_";
      selLvl6.querySelectorAll('option').forEach(o => {
        if (o.getAttribute('parent') === e.target.value) {
          o.style.display = 'block';
        } else {
          if (o.value !== "_select_") {
            o.style.display = 'none';
          }
        }
      });
    } else {
      lvl5 = "";
      selLvl6.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    }
  };
  const changeLvl6 = e => {
    if (e.target.value === "_select_") {
      lvl6 = "";
      e.target.querySelectorAll('option').forEach(o => {
        o.style.display = 'block';
      });
    } else {
      lvl6 = e.target.selectedOptions[0].text;//e.target.value;
    }
  };

  return (
    <div id="searchComponent">
      <div id="mainSearch" className="inline-block mb-1 relative z-10" style={{ lineHeight: '27px', width: '600px' }}>
        <span className="float-left"><AsyncSearch id="pname" findBy="pname" findWhat="prod" onSet={onPnameSet} /></span>
        <input type="button" value="Find" className="w-28 bg-white border-solid border border-gray-400 rounded-full inline-block p-1 ml-2 cursor-pointer text-gray-600 self-center disabled:opacity-50 float-left" onClick={() => { findProducts() }} />
        <div className="rounded-full bg-white border-solid border border-gray-400 inline-block p-1 pr-2 pl-2 cursor-pointer ml-2 text-gray-600 self-center" style={{ display: byNameOnly === true ? "none" : "" }} onClick={() => { toggleAdvancedSearch() }}><span className="ml-1">Advanced search</span><BiCaretDown className="ml-2 inline" /></div>
      </div>
      {
        toggleAll &&
        <div id="advancedSearchContainer">
          <div className="clear-both mb-2 inline-block">
            <span className="ml-2 float-left z-20">
              <select id="ctg_lvl1" onChange={changeLvl1}>
                <option value="_select_">1st level...</option>
                {
                  categories.length &&
                  categories[0]["lvl1"].map(entry => (
                    <option value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl2" onChange={changeLvl2}>
                <option value="_select_">2nd level...</option>
                {
                  categories.length &&
                  categories[1]["lvl2"].map(entry => (
                    <option parent={entry.parent} value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl3" onChange={changeLvl3}>
                <option value="_select_">3rd level...</option>
                {
                  categories.length &&
                  categories[2]["lvl3"].map(entry => (
                    <option parent={entry.parent} value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl4" onChange={changeLvl4}>
                <option value="_select_">4th level...</option>
                {
                  categories.length &&
                  categories[3]["lvl4"].map(entry => (
                    <option parent={entry.parent} value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl5" onChange={changeLvl5}>
                <option value="_select_">5th level...</option>
                {
                  categories.length &&
                  categories[4]["lvl5"].map(entry => (
                    <option parent={entry.parent} value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
            <span className="ml-4 float-left">
              <select id="ctg_lvl6" onChange={changeLvl6}>
                <option value="_select_">6th level...</option>
                {
                  categories.length &&
                  categories[5]["lvl6"].map(entry => (
                    <option parent={entry.parent} value={entry._id}>{entry.name}</option>
                  ))
                }
              </select>
            </span>
          </div>
          <div className="clear-both inline-block mb-2">
            <span className="ml-2 float-left"><AsyncSearch findBy="pnnec" findWhat="prod" onSet={onPnnecSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="rtlr" findWhat="prod" onSet={onRtlrSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="cbrnd" findWhat="prod" onSet={onCbrndSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="brnd" findWhat="prod" onSet={onBrndSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="sbrnd" findWhat="prod" onSet={onSbrndSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="ctry" findWhat="prod" onSet={onCtrySet} /></span>
          </div>
          <div className="clear-both inline-block">
            <span className="ml-2 float-left"><AsyncSearch findBy="dtnm" findWhat="prod" onSet={onDtnmSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="unit" findWhat="prod" onSet={onUnitSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="szwt" findWhat="prod" onSet={onSzwtSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="desc" findWhat="prod" onSet={onDescSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="price" findWhat="prod" onSet={onPriceSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="stid" findWhat="prod" onSet={onStidSet} /></span>
          </div>
          <div className="clear-both mt-2 inline-block">
            <span className="ml-2 float-left"><AsyncSearch findBy="nump" findWhat="prod" onSet={onNumpSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="dim" findWhat="prod" onSet={onDimSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="ean" findWhat="prod" onSet={onEanSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="upc" findWhat="prod" onSet={onUpcSet} /></span>
            <span className="ml-4 float-left"><AsyncSearch findBy="asin" findWhat="prod" onSet={onAsinSet} /></span>
          </div>
        </div>
      }
    </div>
  );
}

export default SearchProducts;