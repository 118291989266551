import ReactTooltip from 'react-tooltip';
import { APIURL, IMGURL, BlockUI, Notify, UnblockUI, UnblockUIError, GetToken } from './Helper';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';

import { projectProductsUpdated } from './Products';
import { projectShelvesUpdated } from './Shelves';
import { projectPlanogramsUpdated } from './Planograms';
import { projectEnvironmentsUpdated } from './Environments';

export const ACCESSORS = [
  "lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6", "pnnec", "pname", "pnpng", "dtnm", "unit", "szwt", "desc", "cbrnd", "brnd", "sbrnd", "price", "stid", "nump", "ctry", "rtlr", "dim", "ean", "upc", "asin"
];
export const ACCESSORS_VALUES = [
  "1st level", "2nd level", "3rd level", "4th level", "5th level", "6th level", "Product Name (non-english characters)", "Product Name", "Product Full Name (Product name + .png)", "Data Name", "Unit of Measurement", "Product size/weight", "Product Description 1", "Holding company/Mother-brand", "Brand", "Sub-brand", "Product Price", "CDB stimuli ID", "Number of project", "Country", "Retailer", "Dimension", "EAN", "UPC", "ASIN"
];

export const ACCESSORS_SHELVES = [
  "img", "stat", "stype", "acc", "len", "rows", "mat", "stg", "tech", "pnum", "ctry", "rtlr", "lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6", "nsku"
];
export const ACCESSORS_VALUES_SHELVES = [
  "Image Name", "Shelf status", "Store type", "Shelf accessories", "Shelf length", "Number of rows", "Material", "Storage type", "Technical feature", "Product Number", "Country", "Retailer", "1st level", "2nd level", "3rd level", "4th level", "5th level", "6th level", "Number of SKU on the shelf"
];

export const ACCESSORS_PLANOGRAMS = [
  "img", "ctry", "date", "rtlr", "lvl1", "lvl2", "lvl3", "lvl4", "lvl5", "lvl6"
];
export const ACCESSORS_VALUES_PLANOGRAMS = [
  "Image Name", "Country", "Date", "Retailer", "1st level", "2nd level", "3rd level", "4th level", "5th level", "6th level"
];

export const ACCESSORS_ENVIRONMENTS = [
  "img", "strt", "envr", "rtlr", "ctry"
];
export const ACCESSORS_VALUES_ENVIRONMENTS = [
  "Image Name", "Store type", "Environment type", "Retailer", "Country"
];

const continueWithUpload = (files, uploadULR, imagesForUpload, objectType, objectID) => {
  let filesToUploadCount = files.length;
  const uploadDiv = document.createElement('div');
  uploadDiv.id = 'uploadingProgressContainer';
  uploadDiv.style.display = 'block';
  document.body.appendChild(uploadDiv);
  const token = GetToken();
  for (let i = 0; i < files.length; ++i) {
    const file = files[i];
    const formData = new FormData();
    const fileName = file.name;
    formData.append("file[]", file, fileName);

    const entryDiv = document.createElement("div");
    entryDiv.style.cssText = "width: 740px; margin: 5px 10px 0px; background: rgb(50, 195, 88); padding: 0px 4px 4px; border-radius: 7px; border: solid 1px #444;";
    entryDiv.innerHTML = "<div style='font-size: 11px; margin-right: 5px; margin-bottom: 3px width: 100%; clear: both; text-align: left;'>" + fileName + "</div><div id='" + fileName + "_progress' class='bar'></div>";
    uploadDiv.append(entryDiv);

    /*eslint-disable */
    axios.post(APIURL + uploadULR, formData, {
      onUploadProgress: progressEvent => {          
        if (document.getElementById(fileName + "_progress")) {
          const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
          document.getElementById(fileName + "_progress").style.width = progress + "%";
        }          
      }
    }).then(res => {
      if (res.status === 200) {
        if ((res.data + "").indexOf("Fatal error") > -1) {
            Notify({
                title: "ERROR!",
                message: "Fatal error: Out of memory. Image thumbnail is not created!",
                type: "danger",
                duration: 8000
            });
        }
        --filesToUploadCount;
        if (document.getElementById(fileName + "_progress")) {
          document.getElementById(fileName + "_progress").parentElement.remove();
        }
        if (filesToUploadCount === 0) {
          uploadDiv.remove();
          if (objectType === "products") {
            axios.get(APIURL + objectType + '.php?action=aimg&id=' + objectID + '&imgs=' + imagesForUpload.join(',') + '&t=' + token)
              .then(res => {
                if (res.status === 200) {
                  projectProductsUpdated.dispatch();
                  Notify({
                    title: "Success",
                    message: "Product updated.",
                    type: "success",
                    duration: 2200
                  });
                  UnblockUI();
                } else {
                  UnblockUIError(res.statusText);
                }
              }).catch(err => {
                UnblockUIError(err);
              });
          } else {
            axios.get(APIURL + objectType + '.php?action=uimg&id=' + objectID + '&img=' + imagesForUpload + '&t=' + token)
              .then(res => {
                if (res.status === 200) {
                  switch (objectType) {
                    case "shelves":
                      projectShelvesUpdated.dispatch();
                      break;
                    
                    case "planograms":
                      projectPlanogramsUpdated.dispatch();
                      break;
                    
                    case "environments":
                      projectEnvironmentsUpdated.dispatch();
                      break;
                  }
                  
                  Notify({
                    title: "Success",
                    message: "Image uploaded.",
                    type: "success",
                    duration: 2200
                  });
                  UnblockUI();
                } else {
                  UnblockUIError(res.statusText);
                }
              }).catch(err => {
                UnblockUIError(err);
              });
          }
        }
      } else {
        UnblockUIError("Failed to upload file: " + fileName);
      }
    }).catch(err => {
      UnblockUIError(err);
    });
    /*eslint-enable */
  }
};

const onDragOver = event => {
  if (["prodImg", "shlvImg", "planImg", "envrImg"].indexOf(event.currentTarget.className) > -1) {
    event.currentTarget.style.backgroundColor = '#ffa63e';
    event.dataTransfer.dropEffect = 'copy';
  }
  event.stopPropagation();
  event.preventDefault();
  return false;
};
const onDragLeave = event => {
  if (["prodImg", "shlvImg", "planImg", "envrImg"].indexOf(event.currentTarget.className) > -1) {
    event.currentTarget.style.backgroundColor = '';
  }
  event.preventDefault();
  return false;
};
const onDrop = event => {
  event.preventDefault();
  if (["prodImg", "shlvImg", "planImg", "envrImg"].indexOf(event.currentTarget.className) > -1) {
    event.currentTarget.style.backgroundColor = '';
    const token = GetToken();
    const id = event.currentTarget.id;
    const files = [];
    const droppedFiles = event.dataTransfer.files;
    const imagesForUpload = [];
    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];
      if (file.type.indexOf("image") > -1) {
        imagesForUpload.push(file.name);
        files.push(file);
      } else {
        Notify({
          title: "ERROR!",
          message: "File " + file.name + " is not an image.",
          type: "danger",
          duration: 3000
        });
      }
    }
    if (files.length > 0) {
      let pathForUpload = '';
      let uploadURL = '';
      switch (event.currentTarget.className) {
        case "prodImg":
          pathForUpload = 'products';
          uploadURL = 'uploadProducts.php';
          break;
      
        case "shlvImg":
          pathForUpload = 'shelves';
          uploadURL = 'uploadShelves.php';
          break;
      
        case "planImg":
          pathForUpload = 'planograms';
          uploadURL = 'uploadPlanograms.php';
          break;
      
        case "envrImg":
          pathForUpload = 'environments';
          uploadURL = 'uploadEnvironments.php';
          break;
      
        default:
      }
      BlockUI();
      axios.get(APIURL + pathForUpload + '.php?action=gimgs&nms=' + imagesForUpload.join() + '&t=' + token).then(response => {
        if (response.data.length === 0) {
          // images are not in the database
          if (pathForUpload === 'products') {
            if (window.confirm('Product will be updated with following images:\n\n' +
              imagesForUpload.join() +
              '\n\nAre you sure you want to proceed and update product with these images?')) {
              continueWithUpload(files, uploadURL, imagesForUpload, pathForUpload, id);
            } else {
              UnblockUI();
            }
          } else {
            if (imagesForUpload.length > 1) {
              UnblockUIError('Please upload only one image!');
            } else {
              if (window.confirm('Entry image will be updated with image:\n\n' +
                imagesForUpload +
                '\n\nAre you sure you want to overwrite current image with this one? ')) {
                continueWithUpload(files, uploadURL, imagesForUpload, pathForUpload, id);
              } else {
                UnblockUI();
              }
            }
          }
        } else {
          if (pathForUpload === 'products') {
            if (window.confirm('Following images are already in the database:\n\n' +
              response.data.join('\n') +
              '\n\nDo you want to proceed and overwrite existing images?')) {
              continueWithUpload(files, uploadURL, imagesForUpload, pathForUpload, id);
            } else {
              UnblockUI();
            }
          } else {
            if (imagesForUpload.length > 1) {
              UnblockUIError('Please upload only one image!');
            } else {
              if (window.confirm('This image is already in the database:\n\n' +
                response.data.join('\n') +
                '\n\nDo you want to proceed and overwrite existing image?')) {
                continueWithUpload(files, uploadURL, imagesForUpload, pathForUpload, id);
              } else {
                UnblockUI();
              }
            }
          }
        }
      });
    }
  }
};

export const COLUMNS_PRODUCTS = [
  {
    Header: "Picture(s)",
    Footer: "Picture(s)",
    accessor: "pic",
    Cell: row => {
      let pnpng = row.row.original.pnpng;
      let fullImg = [];
      if (pnpng) {
        if (pnpng.indexOf(";") > 0) {
          pnpng = pnpng.split(";");
        } else {
          pnpng = [pnpng];
        }
        const imgnames = pnpng.slice(0);
        for (let i = 0; i < imgnames.length; i++) {
          if (document.getElementById('dropDiv') != null && document.getElementById(pnpng[i]) != null) {
            pnpng[i] = fullImg[i] = document.getElementById(pnpng[i]).src;
          } else {
            const ext = pnpng[i].substr(pnpng[i].lastIndexOf('.'), pnpng[i].length);
            pnpng[i] = IMGURL + (pnpng[i].indexOf("projprodimgs") > -1 ? "" : "prodimgs/") + pnpng[i].replace(ext, "_thumb" + ext);
            fullImg[i] = IMGURL + (imgnames[i].indexOf("projprodimgs") > -1 ? "" : "prodimgs/") + imgnames[i];
          }
        }

        const settings = {
          dots: false,
          infinite: false,
          speed: 100,
          slidesToShow: 1,
          slidesToScroll: 1
        };
        
        if (pnpng.length === 1) { // single image
          return <div onDrop={onDrop} className="prodImg" onDragOver={onDragOver} onDragLeave={onDragLeave} id={row.row.original._id}> {
            pnpng.map((e, i) => (
              <>
              <div className="overflow-hidden w-full">
                <a href={fullImg[i]} onClick={e => {
                    e.preventDefault();
                }}>
                  <img id={"img_" + pnpng[i]} alt={imgnames[i]} className="tableImage" height={34} data-tip data-for={pnpng[i]} src={pnpng[i]} />
                </a>
              </div>
              <ReactTooltip id={pnpng[i]} place="right" backgroundColor="#424242" border="true" borderColor="#111111" effect="float" afterShow={_ => {
                document.getElementById("tooltip_" + pnpng[i]).style.width = document.getElementById("img_" + pnpng[i]).naturalWidth + "px";
              }}>
                <div id={"tooltip_" + pnpng[i]} style={{ width: "180px", height: "180px", maxWidth: "180px", maxHeight: "180px", backgroundImage: `url('${pnpng[i]}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}></div>
              </ReactTooltip>
              </>
            ))}
          </div>
        }
        return <div onDrop={onDrop} className="prodImg" onDragOver={onDragOver} onDragLeave={onDragLeave} id={row.row.original._id}><Slider {...settings}> {
          pnpng.map((e, i) => (
            <a href={fullImg[i]} key={e} onClick={e => {
              e.preventDefault();
            }}>
              <img id={"img_" + pnpng[i]} alt={imgnames[i]} className="tableImage" height={34} data-tip data-for={pnpng[i]} src={pnpng[i]} />
            </a>
          ))}
        </Slider>
        {
          pnpng.map((e, i) => (
            <ReactTooltip id={pnpng[i]} place="right" backgroundColor="#424242" border="true" borderColor="#111111" effect="float" afterShow={_ => {
              document.getElementById("tooltip_" + pnpng[i]).style.width = document.getElementById("img_" + pnpng[i]).naturalWidth + "px";
            }}>
              <div id={"tooltip_" + pnpng[i]} style={{ width: "180px", height: "180px", maxWidth: "180px", maxHeight: "180px", backgroundImage: `url('${pnpng[i]}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}></div>
            </ReactTooltip>
          ))
        }
        </div>
      }
      else {
        return <div onDrop={onDrop} className="prodImg" onDragOver={onDragOver} onDragLeave={onDragLeave} id={row.row.original._id}></div>
      }
    }
  },
  {
    Header: "1st level",
    Footer: "1st level",
    accessor: "lvl1"
  },
  {
    Header: "2nd level",
    Footer: "2nd level",
    accessor: "lvl2"
  },
  {
    Header: "3rd level",
    Footer: "3rd level",
    accessor: "lvl3"
  },
  {
    Header: "4th level",
    Footer: "4th level",
    accessor: "lvl4"
  },
  {
    Header: "5th level",
    Footer: "5th level",
    accessor: "lvl5"
  },
  {
    Header: "6th level",
    Footer: "6th level",
    accessor: "lvl6"
  },
  {
    Header: "Product Name (non-english characters)",
    Footer: "Product Name (non-english characters)",
    accessor: "pnnec"
  },
  {
    Header: "Product Name",
    Footer: "Product Name",
    accessor: "pname"
  },
  {
    Header: "Product Full Name (Product name + .png)",
    Footer: "Product Full Name (Product name + .png)",
    accessor: "pnpng"
  },
  {
    Header: "Data Name",
    Footer: "Data Name",
    accessor: "dtnm"
  },
  {
    Header: "Unit of Measurement",
    Footer: "Unit of Measurement",
    accessor: "unit"
  },
  {
    Header: "Product size/weight",
    Footer: "Product size/weight",
    accessor: "szwt"
  },
  {
    Header: "Product Description 1",
    Footer: "Product Description 1",
    accessor: "desc"
  },
  {
    Header: "Holding company/Mother-brand",
    Footer: "Holding company/Mother-brand",
    accessor: "cbrnd"
  },
  {
    Header: "Brand",
    Footer: "Brand",
    accessor: "brnd"
  },
  {
    Header: "Sub-brand",
    Footer: "Sub-brand",
    accessor: "sbrnd"
  },
  {
    Header: "Product Price",
    Footer: "Product Price",
    accessor: "price"
  },
  {
    Header: "CDB stimuli ID",
    Footer: "CDB stimuli ID",
    accessor: "stid"
  },
  {
    Header: "Number of project",
    Footer: "Number of project",
    accessor: "nump"
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "ctry"
  },
  {
    Header: "Retailer",
    Footer: "Retailer",
    accessor: "rtlr"
  },
  {
    Header: "Dimension",
    Footer: "Dimension",
    accessor: "dim"
  },
  {
    Header: "EAN",
    Footer: "EAN",
    accessor: "ean"
  },
  {
    Header: "UPC",
    Footer: "UPC",
    accessor: "upc"
  },
  {
    Header: "ASIN",
    Footer: "ASIN",
    accessor: "asin"
  }
];

export const COLUMNS_SHELVES = [
  {
    Header: "Picture",
    Footer: "Picture",
    accessor: "pic",
    Cell: row => {
      let img = row.row.original.img;
      let fullImg = img;
      if (img) {
        const imgname = row.row.original.img;
        if (document.getElementById('dropDiv') != null && document.getElementById(img) != null) {
          img = fullImg = document.getElementById(img).src;
        } else {
          const ext = img.substr(img.lastIndexOf('.'), img.length);
          img = IMGURL + "shelvimgs/" + img.replace(ext, "_thumb" + ext);
          fullImg = IMGURL + "shelvimgs/" + fullImg;
        }
        return <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} id={row.row.original._id} className="shlvImg" style={{ overflow: "hidden", width: "100%" }}>
          <a href={fullImg} onClick={e => {
              e.preventDefault();
          }}>
            <img id={"img_" + img} alt={imgname} className="tableImage" height={34} data-tip data-for={img} src={img} />
          </a>
          <ReactTooltip id={img} place="right" backgroundColor="#424242" border="true" borderColor="#111111" effect="float" afterShow={_ => {
            document.getElementById("tooltip_" + img).style.width = document.getElementById("img_" + img).naturalWidth + "px";
          }}>
            <div id={"tooltip_" + img} style={{ width: "180px", height: "180px", maxWidth: "180px", maxHeight: "180px", backgroundImage: `url('${img}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}></div>
          </ReactTooltip>
        </div>
      }
      else {
        return <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} id={row.row.original._id} className="shlvImg"></div>
      }
    }
  },
  {
    Header: "Image Name",
    Footer: "Image Name",
    accessor: "img"
  },
  {
    Header: "Shelf status",
    Footer: "Shelf status",
    accessor: "stat"
  },
  {
    Header: "Store type",
    Footer: "Store type",
    accessor: "stype"
  },
  {
    Header: "Shelf accessories",
    Footer: "Shelf accessories",
    accessor: "acc"
  },
  {
    Header: "Shelf length",
    Footer: "Shelf length",
    accessor: "len"
  },
  {
    Header: "Number of rows",
    Footer: "Number of rows",
    accessor: "rows"
  },
  {
    Header: "Material",
    Footer: "Material",
    accessor: "mat"
  },
  {
    Header: "Storage type",
    Footer: "Storage type",
    accessor: "stg"
  },
  {
    Header: "Technical feature",
    Footer: "Technical feature",
    accessor: "tech"
  },
  {
    Header: "Product Number",
    Footer: "Product Number",
    accessor: "pnum"
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "ctry"
  },
  {
    Header: "Retailer",
    Footer: "Retailer",
    accessor: "rtlr"
  },
  {
    Header: "1st level",
    Footer: "1st level",
    accessor: "lvl1"
  },
  {
    Header: "2nd level",
    Footer: "2nd level",
    accessor: "lvl2"
  },
  {
    Header: "3rd level",
    Footer: "3rd level",
    accessor: "lvl3"
  },
  {
    Header: "4th level",
    Footer: "4th level",
    accessor: "lvl4"
  },
  {
    Header: "5th level",
    Footer: "5th level",
    accessor: "lvl5"
  },
  {
    Header: "6th level",
    Footer: "6th level",
    accessor: "lvl6"
  },
  {
    Header: "Number of SKU on the shelf",
    Footer: "Number of SKU on the shelf",
    accessor: "nsku"
  }  
];

export const COLUMNS_PLANOGRAMS = [
  {
    Header: "Picture",
    Footer: "Picture",
    accessor: "pic",
    Cell: row => {
      let img = row.row.original.img;
      let fullImg = img;
      if (img) {
        const imgname = row.row.original.img;
        if (document.getElementById('dropDiv') != null && document.getElementById(img) != null) {
          img = fullImg = document.getElementById(img).src;
        } else {
          const ext = img.substr(img.lastIndexOf('.'), img.length);
          img = IMGURL + "planoimgs/" + img.replace(ext, "_thumb" + ext);
          fullImg = IMGURL + "planoimgs/" + fullImg;
        }
        return <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} id={row.row.original._id} className="planImg" style={{ overflow: "hidden", width: "100%" }}>
          <a href={fullImg} onClick={e => {
              e.preventDefault();
          }}>
            <img id={"img_" + img} alt={imgname} className="tableImage" height={34} data-tip data-for={img} src={img} />
          </a>
          <ReactTooltip id={img} place="right" backgroundColor="#424242" border="true" borderColor="#111111" effect="float" afterShow={_ => {
            document.getElementById("tooltip_" + img).style.width = document.getElementById("img_" + img).naturalWidth + "px";
          }}>
            <div id={"tooltip_" + img} style={{ width: "180px", height: "180px", maxWidth: "180px", maxHeight: "180px", backgroundImage: `url('${img}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}></div>
          </ReactTooltip>
        </div>
      } else {
        return <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} id={row.row.original._id}  className="planImg"></div>
      }
    }
  },
  {
    Header: "Image Name",
    Footer: "Image Name",
    accessor: "img"
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "ctry"
  },
  {
    Header: "Date",
    Footer: "Date",
    accessor: "date"
  },
  {
    Header: "Retailer",
    Footer: "Retailer",
    accessor: "rtlr"
  },
  {
    Header: "1st level",
    Footer: "1st level",
    accessor: "lvl1"
  },
  {
    Header: "2nd level",
    Footer: "2nd level",
    accessor: "lvl2"
  },
  {
    Header: "3rd level",
    Footer: "3rd level",
    accessor: "lvl3"
  },
  {
    Header: "4th level",
    Footer: "4th level",
    accessor: "lvl4"
  },
  {
    Header: "5th level",
    Footer: "5th level",
    accessor: "lvl5"
  },
  {
    Header: "6th level",
    Footer: "6th level",
    accessor: "lvl6"
  } 
];

export const COLUMNS_ENVIRONMENTS = [
  {
    Header: "Picture",
    Footer: "Picture",
    accessor: "pic",
    Cell: row => {
      let img = row.row.original.img;
      let fullImg = img;
      if (img) {
        const imgname = row.row.original.img;
        if (document.getElementById('dropDiv') != null && document.getElementById(img) != null) {
          img = fullImg = document.getElementById(img).src;
        } else {
          const ext = img.substr(img.lastIndexOf('.'), img.length);
          img = IMGURL + "envimgs/" + img.replace(ext, "_thumb" + ext);
          fullImg = IMGURL + "envimgs/" + fullImg;
        }
        return <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} id={row.row.original._id} className="envrImg" style={{ overflow: "hidden", width: "100%" }}>
          <a href={fullImg} onClick={e => {
              e.preventDefault();
          }}>
            <img id={"img_" + img} alt={imgname} className="tableImage" height={34} data-tip data-for={img} src={img} />
          </a>
          <ReactTooltip id={img} place="right" backgroundColor="#424242" border="true" borderColor="#111111" effect="float" afterShow={_ => {
            document.getElementById("tooltip_" + img).style.width = document.getElementById("img_" + img).naturalWidth + "px";
          }}>
            <div id={"tooltip_" + img} style={{ width: "180px", height: "180px", maxWidth: "180px", maxHeight: "180px", backgroundImage: `url('${img}')`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}></div>
          </ReactTooltip>
        </div>
      } else {
        return <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} id={row.row.original._id}  className="envrImg"></div>
      }
    }
  },
  {
    Header: "Image Name",
    Footer: "Image Name",
    accessor: "img"
  },
  {
    Header: "Store type",
    Footer: "Store type",
    accessor: "strt"
  },
  {
    Header: "Environment type",
    Footer: "Environment type",
    accessor: "envr"
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "ctry"
  },
  {
    Header: "Retailer",
    Footer: "Retailer",
    accessor: "rtlr"
  } 
];